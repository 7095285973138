// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {String} $media - Screen type
@mixin respond-to($media) {
  @if $media == xmobile {
    @media only screen and (max-width: map-get($breakpoints, 'small') - 1) { @content; }
  }
  @else if $media == mobile {
    @media only screen and (min-width: map-get($breakpoints, 'small')) and (max-width: map-get($breakpoints, 'medium') - 1) { @content; }
  }
  @else if $media == tablet_v {
    @media only screen and (min-width: map-get($breakpoints, 'medium')) and (max-width: map-get($breakpoints, 'large') - 1) { @content; }
  }
  @else if $media == tablet_p {
    @media only screen and (min-width: map-get($breakpoints, 'large')) and (max-width: map-get($breakpoints, 'xlarge') - 1) { @content; }
  }
  @else if $media == desktop {
    @media only screen and (min-width: map-get($breakpoints, 'xlarge')) { @content; }
  }
}

/// Convert px to rem
/// @author Eugen Zhyzhykin
/// @param {String} $target - size in px
@function rem ($target, $context: $main-font-size) {
	@return ($target / $context) * 1rem;
}

