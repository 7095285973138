// -----------------------------------------------------------------------------
// This file contains all styles related to the chart component.
// -----------------------------------------------------------------------------
$grey: #b2b2b2;
$dark-grey: #7f7f7f;
$color-men: #2ec2e0;
$color-women: #fd8234;
$color-teens: #cef181;
$color-twens: #9cc93b;
$color-adults: #6b8928;
$color-matures: #394916;
$color-buyer: #ff6e14;
$color-non-buyer: #ff6e14;
$color-price: #1e82a0;
/*
.chart-wrap {
  margin-top: rem(40px);
  display: none;

  .bars-wrap {
    border-bottom: solid 1px #000;
    height: 160px;
    width: 100%;
    position: relative;

    .line-max, .line-avg, .line-min {
      width: 100%;
      height: 1px;
      background: $grey;
      position: absolute;
      bottom: 100%;

      label {
        font-size: rem(9px);
        color: $grey;
        position: relative;
        top: -15px;
        left: 3px;
      }
    }

    .line-labels {
      width: 1px;
      height: 100%;
      background: $grey;
      position: absolute;
      bottom: 0;
      left: rem(49px);
    }

    .bars {
      height: 100%;
      width: calc(100% - #{rem(50px)});
      margin-left: rem(50px);
      display: flex;

      .bar {
        width: 10%;
        position: relative;

        .scale-top2, .scale-top1, .scale-label {
          width: 50%;
          background: $dark-grey;
          position: absolute;
          bottom: 0;
          left: 25%;
          text-align: center;
          color: #fff;
          min-height: rem(16px);
          transition: height .5s ease-in-out;
        }

        .scale-top1 {
          background: $grey;
          left: 35%;
        }

        .scale-label {
          background: none;

          &.top1 {
            left: 35%;
          }
        }

        .scale-men, .scale-women, .scale-teens, .scale-twens, .scale-adults, .scale-matures {
          width: 30%;
          background: $color-men;
          position: absolute;
          bottom: 0;
          left: 25%;
          height: 6px;
          margin-bottom: -3px;
          display: none;
        }

        .scale-women {
          background: $color-women;
          left: 45%;
        }

        .scale-teens {
          background: $color-teens;
          width: 16%;
        }

        .scale-twens {
          background: $color-twens;
          width: 16%;
          left: 36%;
        }

        .scale-adults {
          background: $color-adults;
          width: 16%;
          left: 48%;
        }

        .scale-matures {
          background: $color-matures;
          width: 16%;
          left: 59%;
        }
      }
    }
  }

  .bars-info {
    width: calc(100% - #{rem(50px)});
    margin-left: rem(49px);
    display: flex;
    border-left: solid 1px $grey;

    .info {
      width: 10%;
      position: relative;
      border-right: solid 1px $grey;
      font-size: rem(9px);
      text-align: center;
      padding: 6px 6px 46px 6px;
      color: $dark-grey;

      b {
        min-height: 2.8em;
        margin-bottom: rem(4px);
        display: block;
        color: $main-text-color;
      }

      .btn-info {
        margin: 0 0 0 -14px;
        position: absolute;
        bottom: 6px;
      }
    }
  }

  .table {
    width: calc(100% - #{rem(50px)});
    margin-left: rem(49px);
    // border-left: solid 1px $grey;
    border-bottom: solid 1px $grey;
    position: relative;
    font-size: rem(12px);
    text-align: center;
    display: none;
    height: 140px;
    margin-top: 60px;

    &.active {
      display: flex;
    }

    .col {
      width: 10%;
      position: relative;
      // border-right: solid 1px $grey;
    }

    .col-labels {
      position: absolute;
      width: 50px;
      left: -50px;
      top: 0;
      text-align: right;
      font-size: rem(9px);

      span:last-child {
        border-bottom: solid 1px $grey;
      }
    }

    span {
      padding: 0 6px;
      height: rem(20px);
      line-height: rem(20px);
      color: $dark-grey;
      display: block;
      border-top: solid 1px $grey;

      b {
        color: $main-text-color;
      }

      u {
        display: inline-block;
        text-decoration: none;
        width: 16px;
        text-align: right;
      }
    }
  }

  .checkboxes {
    margin-top: rem(46px);
    display: inline-block;

    .checkbox {
      margin-bottom: 8px;

      &.active {
        .inactive {
          display: none;
        }

        .active {
          display: inline-block;
        }
      }

      .inactive {
        display: inline-block;
      }

      .active {
        display: none;
      }

      .color-men, .color-women, .color-teens, .color-twens, .color-adults, .color-matures {
        display: inline-block;
        width: 7px;
        height: 7px;
        margin: 0 15px 0 5px;
      }

      .color-men {
        background: $color-men;
      }

      .color-women {
        background: $color-women;
      }

      .color-teens {
        background: $color-teens;
      }

      .color-twens {
        background: $color-twens;
      }

      .color-adults {
        background: $color-adults;
      }

      .color-matures {
        background: $color-matures;
      }
    }
  }
}
*/
.chart2-wrap {
  margin-top: rem(60px);
  display: none;

  .lines-wrap {
    padding-right: 20px;
    position: relative;

    .result-table {
      .col-1 { width: 20%; padding: 0 5px; }
      .col-2 { width: 73%; padding: 0 5px; position: relative; }
      .col-3 { width: 14%; padding: 0 5px; justify-content: center; }

      .body {
        height: 234px;

        .row {
          border-top: solid 1px $grey;
          border-bottom: 0;

          .number {
            color: $grey;
          }

          .current-price, .estimated-price, .buyer-price, .non-buyer-price {
            position: absolute;
            width: 14px;
            height: 14px;
            background: $color-price;
            border-radius: 50%;
            left: 0;
            top: 50%;
            margin-top: -7px;
            margin-left: -7px;
          }

          .estimated-price, .buyer-price, .non-buyer-price {
            background-color: $color-buyer;
            transition: opacity 0.25s ease-in-out;
          }

          .buyer-price, .non-buyer-price {
            opacity: 0;
          }

          .priceline {
            width: 100px;
            position: relative;

            &:before {
              content: '';
              display: block;
              height: 2px;
              position: relative;
              background: #000;
            }

            .priceline-from, .priceline-to {
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              top: 50%;
              left: -4px;
              margin-top: -4px;
              background: #000;
            }

            .priceline-to {
              left: auto;
              right: -4px;
            }
          }
        }

        .mCSB_outside+.mCSB_scrollTools {
          right: -20px;
        }
      }
    }

    .line-scale {
      border-top: solid 1px $grey;
      padding: 0 14% 0 20%;

      .start {
        display: inline-block;
        color: $dark-grey;
        margin-left: -3px;
        position: absolute;
        padding-top: 6px;
      }

      .mark {
        display: inline-block;
        color: $dark-grey;
        position: relative;
        padding-top: 6px;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 6px;
          background: $grey;
        }

        span {
          display: block;
          transform: translateX(50%);
          text-align: center;
        }
      }
    }
  }

  .chart-footer {
    display: flex;
    justify-content: space-between;
    margin-top: rem(46px);

    .checkboxes {
      display: inline-block;

      .checkbox {
        margin-right: 20px;
        padding-right: 26px;
        display: inline-block;

        &.active {
          .legend {
            display: inline-block;
          }
        }

        .legend {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: 0;
          display: none;
        }

        .color-buyer {
          background: $color-buyer;
        }

        .color-non-buyer {
          background: $color-non-buyer;
        }
      }
    }

    .legend {
      .legend-buyers, .legend-price {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $color-buyer;
        vertical-align: bottom;
        margin-right: 10px;
      }

      .legend-price-line {
        display: inline-block;
        margin-left: 30px;
        margin-right: 10px;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 2px;
          top: -3px;
          position: relative;
          background: #000;
        }

        &:after {
          content: '';
          position: absolute;
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #000;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          margin: -3px 0 0 -3px;
        }
      }

      .legend-price {
        background: $color-price;
        margin-left: 30px;
      }
    }
  }
}

